export function isDefined(arg) {
    if(arg !== null && arg !== 'null' && arg !== undefined && arg !== 'undefined' && arg !== '') {
        return true;
    }
    return false;
}

export function isObjectEmpty(obj) {
    return isDefined(obj) ? Object.keys(obj).length === 0 : true;
}

export function isArrayEmpty(arr) {
    return isDefined(arr) ? arr.length === 0 : true;
}

export default {
    isDefined,
    isObjectEmpty,
    isArrayEmpty
}